import { Capacitor } from "@capacitor/core";
import { hasToken } from "./request";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { ClientLoaderFunctionArgs, redirect } from "@remix-run/react";
import mixpanel from "mixpanel-browser";
import { queryClient } from "./queryClient";
import { OnboardingStatus } from "./types";

export async function checkNotificationPermission({
  request,
}: {
  request: ClientLoaderFunctionArgs["request"];
}) {
  if (!Capacitor.isNativePlatform()) {
    return null;
  }

  if (!hasToken()) {
    return null;
  }

  if (window.localStorage.getItem("notifications_skipped") === "true") {
    return null;
  }

  const { receive } = await FirebaseMessaging.checkPermissions();

  if (receive == "prompt") {
    const nextPath = new URL(request.url).pathname;
    const route = `/notifications?next=${encodeURIComponent(nextPath)}`;
    return redirect(route);
  }

  return null;
}

export function logout() {
  mixpanel.track("Logout");
  mixpanel.reset();
  localStorage.clear();
  queryClient.clear();
  location.href = "/";
}

export function getNavigateLink(
  userType: "mentor" | "mentee",
  onboardingStatus: OnboardingStatus,
) {
  if (onboardingStatus === "completed" || onboardingStatus === "rejected") {
    const next = localStorage.getItem("next");
    if (next && userType === "mentee") {
      localStorage.removeItem("next");
      return next;
    }

    if (userType === "mentor") {
      return "/dashboard/chat";
    }

    return "/dashboard/find";
  }

  return `/onboarding/${onboardingStatus}`;
}
